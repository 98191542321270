<script setup lang="ts">
import type { Icon } from "@kippie/ui/dist/runtime/types/shared";
import { ShippingMethodSidebar } from "#components";

const shippingMethodSidebar = ref<InstanceType<typeof ShippingMethodSidebar>>();
const { selectedShippingMethod } = useCheckout();

const { selectedPickupLocation } = useCart();

const selectedShippingMethodText = computed(() => {
	if (!selectedShippingMethod.value) {
		return {
			name: "Selecteer bezorgmethode",
			icon: "delivery"
		};
	}

	const name =
		selectedShippingMethod.value.type === "pickup" && selectedPickupLocation.value?.name
			? selectedPickupLocation.value.name
			: selectedShippingMethod.value.name;

	const icon = selectedShippingMethod.value?.type === "pickup" ? "store" : "delivery";

	return { name, icon };
});
</script>

<template>
	<span>
		<ClientOnly>
			<slot v-bind="{ shippingMethodSidebar }">
				<button class="flex items-center gap-2" @click="shippingMethodSidebar?.open()">
					<div class="grow overflow-hidden text-ellipsis whitespace-nowrap max-w-[110px]">
						{{ selectedShippingMethodText.name }}
					</div>
					<SvgIcon :name="(selectedShippingMethodText.icon as Icon)" />
				</button>
			</slot>
			<ShippingMethodSidebar ref="shippingMethodSidebar" />

			<template #fallback>
				<button class="flex items-center gap-2" @click="shippingMethodSidebar?.open()">
					<div class="grow overflow-hidden text-ellipsis whitespace-nowrap max-w-[110px]">
						Selecteer bezorgmethode
					</div>
					<SvgIcon name="store" />
				</button>
			</template>
		</ClientOnly>
	</span>
</template>
