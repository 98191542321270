<script setup lang="ts">
import { transformToLink, transformUrl, transformToLinkGroup } from "@/helpers/link-transformers";
import { getRoute } from "~/lib/routes";

const route = useRoute();
const { usps, navigation, newsletter, footer } = await useStoryblokTheme("default");

const { open } = useShoppingCartView();
const { count } = useCart();
const { onViewCart } = useGtagEvents();

const navbar = ref();
const uspbar = ref();
const uspHeight = ref<number>(45);
const navHeight = ref<number>(80);
const navVisible = ref<boolean>(true);
const navOffset = ref<number>(45);
const navFloating = ref<boolean>(false);

const { open: openSearch } = useSearch();
const { loadNavigationElements } = useNavigation({ type: "main-navigation" });

const { data } = await useAsyncData("mainNavigation", () => {
	return loadNavigationElements({ depth: 3 });
});

provide("swNavigation-main-navigation", data);

watch(
	() => route.fullPath,
	() => navbar.value?.close()
);

const prev = ref<number>(0);

if (process.client) {
	uspHeight.value = uspbar.value?.offsetHeight || 45;
	navHeight.value = navbar.value?.offsetHeight || 80;
	prev.value = window.scrollY;
	window.onscroll = () => requestAnimationFrame(calculateNavPosition);
}

function calculateNavPosition(): void {
	const current = window.scrollY;
	const offset = uspHeight.value - current;

	navFloating.value = current > uspHeight.value; // Navbar floats when scroll position is bigger then uspbar
	navOffset.value = offset < 0 ? 0 : offset;

	// when under nav and usp height. Otherwise check if prev is greater as current scroll position
	navVisible.value = (prev.value > current && current !== 0) || current < uspHeight.value + navHeight.value;
	prev.value = current;
}

const { newsletterSubscribe, isNewsletterSubscriber, getNewsletterStatus } = useNewsletter();

const showNewsletter = ref(true);
const snackbar = useSnackbar();
const newsletterSubscribingLoading = ref<boolean>(false);

const submitNewsletter = async (email: string) => {
	newsletterSubscribingLoading.value = true;
	try {
		await newsletterSubscribe({
			email: email,
			option: "subscribe"
		});
		snackbar.open({
			content: "Wat leuk dat je op de hoogte wil blijven van Kippie! Bedankt!",
			icon: "info",
			iconColor: "green"
		});
	} catch (e) {
		snackbar.open({
			content: "Er is iets misgegaan probeer het opnieuw!",
			icon: "info",
			iconColor: "red"
		});
	} finally {
		newsletterSubscribingLoading.value = false;
		getNewsletterStatus().then(() => {
			console.log(isNewsletterSubscriber.value);
			showNewsletter.value = !isNewsletterSubscriber.value;
		});
	}
};

onMounted(() => {
	useInitialShippingState();
});
</script>

<template>
	<div class="bg-sand">
		<div ref="uspbar">
			<KippieUspBar
				:time="+usps.animationTime"
				:items="usps.items.map((v: any) => v.label)"
				:animation="usps.animationType"
			>
				<template #left v-if="usps.left.length">
					<KippieLink
						v-for="item in usps.left"
						:key="item.label"
						:to="transformUrl(item.link.cached_url)"
						:external="item.link.cached_url.includes('http')"
						:target="item.link.cached_url.includes('http') ? '_blank' : '_self'"
						:underline="false"
						class="text-sm font-bold"
					>
						{{ item.label }}
					</KippieLink>
				</template>
				<template #right>
					<div class="flex items-center gap-6 text-sm font-bold">
						<ShippingMethodButton />
						<UspBarSearchButton />
						<UspBarAccountButton />
					</div>
				</template>
			</KippieUspBar>
		</div>
		<div v-if="navFloating" class="w-full h-20 bg-black-light" />
		<Transition
			enter-active-class="duration-200 ease-out"
			enter-from-class="-translate-y-full"
			enter-to-class="translate-0"
			leave-active-class="duration-200 ease-in"
			leave-from-class="translate-0"
			leave-to-class="-translate-y-full"
		>
			<KippieNavbar
				v-show="navVisible"
				ref="navbar"
				:search-placeholder="navigation.searchPlaceholder"
				:links="transformToLink(navigation.sidebarLinks)"
				:small-links="transformToLink(navigation.smallLinks)"
				:cta-links="transformToLink(navigation.ctaLinks)"
				class="z-20 left-0 right-0"
				:class="{ fixed: navFloating }"
				:style="{
					top: `${navOffset}px`
				}"
				@search="openSearch"
			>
				<template #left>
					<button aria-label="hamburger menu" class="min-w-max text-white" @click="navbar?.toggleOpen">
						<SvgIcon name="hamburger" />
					</button>
					<KippieLink :to="getRoute('home')" :underline="false">
						<NuxtImg
							:src="navigation.logo.filename"
							:alt="navigation.logo.alt"
							provider="storyblok"
							class="w-[90px] sm:w-[127px]"
							:width="24"
							format="svg"
						/>
					</KippieLink>
					<KippieLink
						to="/assortiment"
						:external="false"
						:underline="false"
						class="text-white hidden lg:block text-base leading-7 font-medium hover:scale-[102%] duration-200 ease-in-out"
					>
						Assortiment
					</KippieLink>
					<KippieLink
						to="/kippiepannen"
						:external="false"
						:underline="false"
						class="text-white hidden lg:block text-base leading-7 font-medium hover:scale-[102%] duration-200 ease-in-out"
					>
						Kippiepannen
					</KippieLink>
					<KippieLink
						to="/winkels"
						:external="false"
						:underline="false"
						class="text-white hidden lg:block text-base leading-7 font-medium hover:scale-[102%] duration-200 ease-in-out"
					>
						Winkels
					</KippieLink>
				</template>

				<template #right>
					<NuxtLink :to="getRoute('account')" :underline="false" class="flex gap-x-3 items-center group">
						<span class="text-white hidden lg:block text-base leading-7 font-medium"> Account </span>
						<KippieIconButton icon="account" class="group-hover:scale-105" />
					</NuxtLink>
					<button
						class="flex gap-x-3 items-center group"
						@click="
							open();
							onViewCart();
						"
					>
						<span class="text-white hidden lg:block text-base leading-7 font-medium"> Winkelmandje </span>
						<ClientOnly>
							<template #fallback>
								<KippieIconButton icon="shopping-bag" key="loading-cart" fake />
							</template>
							<KippieIconButton icon="shopping-bag" class="group-hover:scale-105" :badge="count" fake />
						</ClientOnly>
					</button>
				</template>

				<template #images>
					<div class="flex flex-col aspect-[1/3] h-full">
						<KippieLink
							v-for="item in navigation.imageLinks"
							:key="item.label"
							:to="transformUrl(item.link.cached_url)"
							:external="item.link.cached_url.includes('http')"
							:target="item.link.cached_url.includes('http') ? '_blank' : '_self'"
							:underline="false"
							class="aspect-square grow overflow-hidden relative"
						>
							<div
								class="absolute inset-0 bg-gradient-to-b from-black/0 to-black/50 p-6 flex flex-col h-full justify-end"
							>
								<span class="font-brush text-white text-3xl leading-[1.9375rem]">
									{{ item.label }}
								</span>
							</div>
							<NuxtImg
								:src="item.image.filename"
								:alt="item.image.alt"
								class="h-full object-cover"
								provider="storyblok"
								:modifiers="{ filters: { focal: item.image.focus } }"
								sizes="xl:500px lg:500px md:500px sm:1px xs:1px"
								:quality="90"
								loading="lazy"
							/>
						</KippieLink>
					</div>
				</template>

				<template #socials>
					<div
						v-for="social in navigation.socials"
						:key="social.icon.id"
						class="hover:scale-110 ease-in-out duration-200"
					>
						<KippieLink
							:to="transformUrl(social.link.cached_url)"
							:underline="false"
							:external="true"
							:aria-label="social.icon"
							target="_blank"
							rel="noreferrer noopener"
						>
							<NuxtImg
								:src="social.icon.filename"
								:alt="social.icon.alt"
								provider="storyblok"
								class="min-w-[1.50rem]"
								:width="24"
								format="svg"
							/>
						</KippieLink>
					</div>
				</template>

				<template #paymentMethods>
					<NuxtImg
						v-for="method in navigation.paymentMethods"
						:key="method._uid"
						:src="method.filename"
						:alt="method.alt"
						provider="storyblok"
						class="min-w-[1.50rem]"
						:width="24"
						format="svg"
					/>
				</template>
			</KippieNavbar>
		</Transition>
		<slot />

		<LazyKippieNewsLetter
			v-if="showNewsletter"
			:title="newsletter.title"
			:text="newsletter.text"
			:email-placeholder="newsletter.placeholder"
			:button-label="newsletter.buttonLabel"
			@submit="submitNewsletter"
		>
			<template #button="{ buttonLabel }">
				<KippieButton color="black" type="submit" block :loading="newsletterSubscribingLoading">
					{{ buttonLabel }}
				</KippieButton>
			</template>
		</LazyKippieNewsLetter>
		<KippieFooter
			:footer-items="transformToLinkGroup(footer.linkGroups)"
			:policies="transformToLink(footer.policies)"
			:copyright="footer.copyright"
			:rights-reserved="footer.rightsReserved"
		>
			<template #socials>
				<KippieLink
					v-for="social in footer.socials"
					:key="social.icon.id"
					:aria-label="social.icon"
					:to="transformUrl(social.link.cached_url)"
					target="_blank"
					external
					class="text-base leading-7 text-white"
				>
					<NuxtImg
						:src="social.icon.filename"
						:alt="social.icon.alt"
						provider="storyblok"
						class="min-w-[1.50rem] invert"
						:width="24"
						format="svg"
					/>
				</KippieLink>
			</template>
		</KippieFooter>
	</div>
</template>
